<template>
<b-card>
<b-form @submit="onSubmit">
    <b-row>
        <b-col class="col-md-6 pt-1">
            <h5 class="pt-1">
                Name
            </h5>
            <b-form-input
                v-model="wheelName"
                placeholder="Name"
            />
        </b-col>
        <b-col class="col-md-6 pt-1">
            <h5 class="pt-1">
                Equipment Class
            </h5>
            <custom-autopopulate
                id="equipmentClass"
                :key="equipmentId"
                v-model="equipmentId"
                module="equipmentclasses"
                retrieve="full_object"
                label="name"
                :value="equipmentId"
                @changed="equipmentSelected"
            />
        </b-col>
        <b-col class="col-md-6 pt-1">
        <h5 class="pt-1">
                Vehicle Code
            </h5>
            <b-form-input
                v-model="vehicleCode"
                placeholder="Vehicle Code"
            />
        </b-col>
    </b-row>
    <b-row>
        <b-col class="col-12 pt-1">
            <h3 class="pt-1">
                    Select applicable positions from below
            </h3>
        </b-col>
        <b-col class="col-md-6 pt-1">
            <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio
                id="radio-group-1"
                v-model="check_forklift"
                :aria-describedby="ariaDescribedby"
                name="check_forklift"
                :value="0"
            >
            Select from non-forklift layout
            </b-form-radio>
            </b-form-group>
        </b-col>
    </b-row>
    <div
        v-if="!loading"
        class="p-2"
    >
        <div
        v-for="(n, i) in rid"
        :key="i"
        >
            <table-layout
                :fields="gettable(n)"
                :cols="cols"
                :selected-cells="selectedCells"
                @selected="cellResponse"
            />
        </div>
    </div>
    <b-row>
        <b-col class="col-md-6 pt-1">
            <b-form-radio
                id="radio-group-2"
                v-model="check_forklift"
                aria-describedby="check_forklift"
                name="check_forklift"
                :value="1"
            >
                Select from forklift layout
            </b-form-radio>
        </b-col>
    </b-row>
    <div
        v-if="!loading"
        class="p-2"
    >
        <div>
            <table-layout
                :fields="getForkliftTable()"
                :cols="forkCols"
                :selected-cells="selectedForkliftCells"
                type="forklift"
                @selected="cellResponse"
            />
        </div>
    </div>
        <b-button
        type="submit"
        variant="primary"
        >
            Submit
        </b-button>
        <GoBack />
    </b-form>
</b-card>
</template>

<script>
import {
    BCard,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormRadio,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import CustomAutopopulate from '../../common/components/CustomAutopopulate';
// import GetTableLayouts from './gettablelayouts';
// import GetTableForklift from './getTableForklift';
import tableLayout from './tableLayout';
import GoBack from '../../common/goBack.vue';

export default {
    components: {
        BCard,
        BForm,
        BButton,
        BRow,
        BCol,
        BFormInput,
        CustomAutopopulate,
        BFormGroup,
        BFormRadio,
        tableLayout,
        GoBack,
    },
    data() {
        return {
            equipmentId: 0,
            fields: '',
            ForkliftFields: '',
            items: [],
            rows: 0,
            cols: 0,
            forkCols: 0,
            forkRows: 0,
            id: 0,
            forkLiftId: 2,
            selected: '',
            selectedCells: [],
            selectedForkliftCells: [],
            vehicleCode: '',
            check_forklift: 0,
            equipmentClass: '',
            wheelName: '',
            rid: 0,
            loading: true,
            name: '',
        };
    },
    created() {
        const routeId = this.$route.params.id;
        this.id = parseInt(routeId, 10);
        this.getdata();
    },
    methods: {
        getdata() {
            this.$helper.showLoading();
            this.$axios
                .get(`/wheellayouts/view/${this.id}`)
                .then(resp => {
                    this.$helper.hideLoading();
                    if (resp.data) {
                        this.wheelName = resp.data.response.results.result.name;
                        this.vehicleCode = resp.data.response.results.result.vehicle_code;
                        this.check_forklift = resp.data.response.results.result.is_forklift_layout;
                        this.equipmentId = resp.data.response.results.result.equipment_class_id;
                        console.log(this.equipmentId);
                        this.equipmentClass = resp.data.response.fields.equipment_class_name.value;
                        if (this.check_forklift) {
                            this.selectedForkliftCells = resp.data.selectedCells;
                        } else {
                            this.selectedCells = resp.data.selectedCells;
                        }
                        this.rows = resp.data.table.rows;
                        this.cols = resp.data.table.columns;
                        this.items = resp.data.data;
                        this.rid = resp.data.table.id;
                        this.forkCols = resp.data.table.forklift_columns;
                        this.forkRows = resp.data.table.forklift_rows;
                        this.loading = false;
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: resp.data.data.message,
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    }
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                    console.error(error);
                });
        },
        async onSubmit(event) {
            event.preventDefault();
            // console.log(this.selectedCells);
            let request = {};
            // let header = null;
            const formdata = new FormData();
            formdata.append('name', this.wheelName);
            formdata.append('equipment_class_id', this.equipmentId);
            formdata.append('vehicle_code', this.vehicleCode);
            formdata.append('is_forklift_layout', this.check_forklift);
            if (this.check_forklift === 0) { formdata.append('selected_cells', this.selectedCells); } else { formdata.append('selected_cells', this.selectedForkliftCells); }
            request = formdata;
            this.$helper.showLoading();
                this.$axios
                    .post(`wheellayouts/update/${this.id}`, request)
                    .then(resp => {
                        this.$helper.hideLoading();
                        if (resp.data) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Wheel Layout Added',
                                    icon: 'CheckIcon',
                                    variant: 'Primary',
                                },
                            });
                            // this.editView = false;
                        }
                    })
                    .catch(err => {
                        this.$helper.hideLoading();
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Oops! Something went wrong',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    });
        },
        gettable(tablecount) {
            return this.items.filter(item => item.id === tablecount);
        },
        getForkliftTable(tablecount) {
            const forkliftFields = this.items.filter(item => item.num_rows_forklift !== 0);
            // console.log(forkliftFields);
            return forkliftFields.slice().reverse();
        },
        cellResponse(val, type) {
            const desiredArr = (type === 'wheel') ? this.selectedCells : this.selectedForkliftCells;
          if (desiredArr.indexOf(val) >= 0) {
            desiredArr.splice(desiredArr.indexOf(val), 1);
          } else {
            desiredArr.push(val);
          }
        },
        equipmentSelected(value) {
            if (value) {
                this.equipmentId = value.id;
                this.equipmentClass = value.name;
            }
        },
    },
};
</script>
